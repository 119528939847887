@import "./../../../basedata/variables";

.preview-container {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;

  h2 {
    @include fontDefault;
    font-size: 1em;
    position: relative;
    z-index: 2;
    padding: 20px 0;
    background-color: $pm-primary;
    color: $white;
    border-radius: 6px;
    margin: 0; }

  .preview-controll {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    transition: all ease 1s;

    .controllers {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
      display: block;
      padding-top: 70%;

      span {
        display: inline-block;
        border: 2px solid $green-500;
        width: 70px;
        height: 70px;
        padding-top: 8px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.7);
        font-size: 30px; } }

    .control-forward {
      right: 0;
      border-left: 1px solid $gray-300;

      svg {
        color: $green-500; } }

    .control-back {
      left: 0;
      span {
        border-color: $red-500; }
      svg {
        color: $red-500; } }
    &.active {
      opacity: 1; } }

  img {
    width: 100%;
    position: relative;
    z-index: 0;
    transform: scale(-1, 1); } }
